:root {
    /* Margin for content on desktop */
    --page-margin-top: 60px;
    /* Size of top header */
    --header-height: 60px;
    /* Margin for content on mobile */
    --page-margin-top-mobile: 46px;
    --page-width-mobile: 414px;
    --input-border-radius: 6px;
    --page-margin-left: 180px;
    --font-size-menu: 18px;

    /* Main content margin / gutter */
    --content-margin-left: 8px;

    --rag-green-light: rgba(0, 128, 0, 0.349);
    --rag-green-lighter: #dbffdb;
    --rag-amber-light: rgb(241, 199, 121);
    --rag-amber-lighter: #f3dbae;
    --rag-red-light: rgb(248, 139, 139);
    --rag-amber-dark: #c69d51;
    --rag-red-dark: #b05b5b;
    --link-hover: blue;
    --border-radius: 4px;
    --title-size: 18px;

    --crumb-divider-foreground-colour: #a0a0a0;
    --crumb-active-foreground-colour: white;
    --crumb-history-foreground-colour: #007ad9;

    --tabview-list-height: 37px;
    --toolbar-height: 38px;
    --button-height: 38px
}

.root-container.theme-light {
    --background-colour: white;
    --background-colour-muted: #cccccc;
    --background-colour-slightly-muted: #f6f6f6;
    --background-colour-menu-selected: #dfe6ff;
    --background-colour-menu-hover: #f0f0f0;
    --background-colour-tabs: white;

    --background-colour-info: #ffd59796;
    --foreground-colour-info: black;

    --foreground-colour: black;
    --foreground-colour-muted: #444;
    --foreground-colour-menu: #253dac;
    --foreground-colour-menu-selected: #2196f3;
    --foreground-colour-menu-hover: #253dac;
    --foreground-colour-tabs: #2196F3;
    --foreground-colour-dim: #a0a0a0;
    --foreground-colour-light: #c0c0c0;

    --border-colour: #a0a0a0;
    --border-colour-muted: #e0e0e0;

    --table-border-colour: #f0f0f0;
    --table-header-colour: #333;
    --table-hover-background-colour: #f5f5f5;
    --table-hover-foreground-colour: black;
    --table-row-odd-background-colour: #fdfdfd;

    --toolbar-background-colour: #f0f0f0;
    --toolbar-foreground-colour: #888;
    --toolbar-chip-background-colour: #9f9f9f;
    --toolbar-chip-foreground-colour: white;
    --toolbar-border-border-colour-selected: #2196F3;
    --toolbar-button-secondary-background-colour: #fefefe;
    --toolbar-button-secondary-foreground-colour: black;
    --toolbar-button-selected-background-colour: blue;
    --toolbar-button-selected-foreground-colour: white;

    --button-primary-foreground-colour: #607D8B;
    --button-primary-background-colour: transparent;
    --button-secondary-background-colour: #e0e0e0;
    --button-secondary-foreground-colour: black;
    --button-hover-foreground-colour: blue;
    --button-hover-background-colour: #f0f0f0;
    --button-muted-foreground-colour: #928f8f;
    --button-muted-background-colour: transparent;

    --search-background-colour: white;
    --search-foreground-colour: #495057;
    --search-border-colour: #e0e0e0;
    --radios-background-colour: #f0f0f0;
    --link-foreground-colour: #007ad9;

    --info-panel-background-colour: #f2f2f2;
    --info-panel-foreground-colour: #888;

    --scrollbar-foreground-colour: silver;
    --dropdown-background-colour: white;
    --dropdown-foreground-colour: black;
    --select-background-colour: white;
    --select-foreground-colour: black;
    --chip-background-colour: #f0f0f0;
    --chip-foreground-colour: black;
    --chip-selected-background-colour: #607D8B;
    --chip-selected-foreground-colour: white;
    --icon-foreground-colour: #888;
    --floorplan-filter: none;
    --panel-header-background-colour: #f8f9fa;
    --panel-header-foreground-colour: black;

    --tabs-tab-foreground-colour: #888;
    --tabs-tab-selected-colour: #007ad9;
    --tabs-border-colour: #eeeeee;

    --tooltip-foreground-colour: white;
    --tooltip-background-colour: #222222;

    --calendar-selected-background-colour: #007ad9;
    --calendar-selected-foreground-colour: white;

    --field-title-foreground-colour: #5e5d5d;
    --focus-foreground-colour: #007ad9;

    --modal-border-colour: none;
    --cta-foreground-colour: #007ad9;

    color-scheme: light;

    --box-shadow: 0px 0px 8px 4px #808080;

    --checked-row-foreground-colour: green;
    --checked-row-backgreound-colour: white;

    --select-hover-background-colour: #ebebeb;
}

.please-wait {
    user-select: none;
    opacity: .8;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}

.place-center {
    place-self: center;
}

.root-container.theme-dark {
    --background-colour: black;
    --background-colour-slightly-muted: #1a1a1a;
    --background-colour-muted: #444;
    --background-colour-menu-selected: rgb(14, 13, 13);
    --background-colour-menu-hover: #ffa500;
    --background-colour-tabs: black;
    --foreground-colour: #fafafa;
    --foreground-colour-muted: #d9d9d9;
    --foreground-colour-dim: #a0a0a0;
    --foreground-colour-menu: #81bae7;
    --foreground-colour-menu-selected: orange;
    --foreground-colour-menu-hover: white;
    --foreground-colour-tabs: #82c4fa;
    --border-colour: rgb(229, 151, 6);
    --border-colour-muted: #202020;

    --table-border-colour: #333;
    --table-header-colour: #f0f0f0;
    --table-hover-background-colour: orange;
    --table-hover-foreground-colour: black;
    --table-row-odd-background-colour: #161616;

    --toolbar-background-colour: #333;
    --toolbar-foreground-colour: white;
    --toolbar-chip-background-colour: #3f3f3f;
    --toolbar-chip-foreground-colour: white;
    --toolbar-border-border-colour-selected: #2196F3;
    --toolbar-button-secondary-background-colour: #888;
    --toolbar-button-secondary-foreground-colour: black;
    --toolbar-button-selected-background-colour: orange;
    --toolbar-button-selected-foreground-colour: black;

    --button-primary-foreground-colour: orange;
    --button-primary-background-colour: transparent;
    --button-secondary-background-colour: #222;
    --button-secondary-foreground-colour: white;
    --button-hover-foreground-colour: orange;
    --button-hover-background-colour: black;
    --button-muted-foreground-colour: #aaaaaa;
    --button-muted-background-colour: transparent;

    --search-background-colour: #222;
    --search-foreground-colour: white;
    --search-border-colour: #333;
    --radios-background-colour: #222;
    --link-foreground-colour: orange;

    --info-panel-background-colour: #333;
    --info-panel-foreground-colour: #e0e0e0;

    --scrollbar-foreground-colour: #a0a0a0;
    --dropdown-background-colour: black;
    --dropdown-foreground-colour: #f0f0f0;

    --select-background-colour: black;
    --select-foreground-colour: #f0f0f0;

    --chip-background-colour: #222;
    --chip-foreground-colour: orange;
    --chip-selected-background-colour: orange;
    --chip-selected-foreground-colour: black;

    --icon-foreground-colour: orange;
    --floorplan-filter: grayscale(100%) brightness(0) invert(1);

    --panel-header-background-colour: #222;
    --panel-header-foreground-colour: #d0d0d0;

    --tabs-tab-foreground-colour: #bebebe;
    --tabs-border-colour: rgb(109, 72, 3);
    --tabs-tab-selected-colour: orange;

    --tooltip-foreground-colour: black;
    --tooltip-background-colour: orange;

    --calendar-selected-background-colour: orange;
    --calendar-selected-foreground-colour: black;

    --field-title-foreground-colour: #d3d3d3;
    --focus-foreground-colour: orange;

    /* Colour of the active (last part of crumb) */
    --crumb-active-foreground-colour: white;
    --crumb-history-foreground-colour: #c0c0c0;

    --modal-border-colour: rgb(208, 136, 2);
    --cta-foreground-colour: #fdd70c;

    --box-shadow: 0px 0px 8px 4px #0e0d0d;

    --checked-row-foreground-colour: #fdd70c;
    --checked-row-backgreound-colour: black;

    --select-hover-background-colour: rgb(109, 72, 3);
}


.root-container.theme-dark-fourd {
    --background-colour: black;
    --background-colour-slightly-muted: #1a1a1a;
    --background-colour-muted: #444;
    --background-colour-menu-selected: rgb(14, 13, 13);
    --background-colour-menu-hover: #009fe3;
    --background-colour-tabs: black;
    --foreground-colour: #fafafa;
    --foreground-colour-muted: #d9d9d9;
    --foreground-colour-dim: #a0a0a0;
    --foreground-colour-menu: #bebebe;
    --foreground-colour-menu-selected: #32c1ff;
    --foreground-colour-menu-hover: white;
    --foreground-colour-tabs: #82c4fa;
    --border-colour: #009fe3;
    --border-colour-muted: #202020;

    --table-border-colour: #333;
    --table-header-colour: #f0f0f0;
    --table-hover-background-colour: #012f42;
    --table-hover-foreground-colour: rgb(255, 255, 255);
    --table-row-odd-background-colour: #161616;

    --toolbar-background-colour: #333;
    --toolbar-foreground-colour: white;
    --toolbar-chip-background-colour: #3f3f3f;
    --toolbar-chip-foreground-colour: white;
    --toolbar-border-border-colour-selected: #2196F3;
    --toolbar-button-secondary-background-colour: #888;
    --toolbar-button-secondary-foreground-colour: black;
    --toolbar-button-selected-background-colour: #009fe3;
    --toolbar-button-selected-foreground-colour: black;

    --button-primary-foreground-colour: #32c1ff;
    --button-primary-background-colour: transparent;
    --button-secondary-background-colour: #222;
    --button-secondary-foreground-colour: white;
    --button-hover-foreground-colour: #009fe3;
    --button-hover-background-colour: black;
    --button-muted-foreground-colour: #aaaaaa;
    --button-muted-background-colour: transparent;

    --search-background-colour: #222;
    --search-foreground-colour: white;
    --search-border-colour: #333;
    --radios-background-colour: #222;
    --link-foreground-colour: #009fe3;

    --info-panel-background-colour: #333;
    --info-panel-foreground-colour: #e0e0e0;

    --scrollbar-foreground-colour: #009fe3;
    --dropdown-background-colour: black;
    --dropdown-foreground-colour: #f0f0f0;

    --select-background-colour: black;
    --select-foreground-colour: #f0f0f0;

    --chip-background-colour: #222;
    --chip-foreground-colour: #009fe3;
    --chip-selected-background-colour: #01293a;
    --chip-selected-foreground-colour: white;

    --icon-foreground-colour: #009fe3;
    --floorplan-filter: grayscale(100%) brightness(0) invert(1);

    --panel-header-background-colour: #222;
    --panel-header-foreground-colour: #d0d0d0;

    --tabs-tab-foreground-colour: #bebebe;
    --tabs-border-colour: #0272a1;
    --tabs-tab-selected-colour: #009fe3;

    --tooltip-foreground-colour: black;
    --tooltip-background-colour: #009fe3;

    --calendar-selected-background-colour: #009fe3;
    --calendar-selected-foreground-colour: black;

    --field-title-foreground-colour: #d3d3d3;
    --focus-foreground-colour: #009fe3;

    --modal-border-colour: rgb(208, 136, 2);
    --cta-foreground-colour: #fdd70c;
}

.root-container.theme-dark,
.root-container.theme-dark-four {
    color-scheme: dark;
}

.root-container.theme-dark-four input[type="time"]::-webkit-calendar-picker-indicator {
    filter: brightness(0) saturate(100%) invert(43%) sepia(28%) saturate(4685%) hue-rotate(170deg) brightness(100%) contrast(101%);
}

.root-container.theme-dark input[type="number"]::-webkit-inner-spin-button {
    filter: invert(72%) sepia(14%) saturate(7243%) hue-rotate(358deg) brightness(89%) contrast(87%);
}

.root-container.theme-dark input[type="time"]::-webkit-calendar-picker-indicator {
    filter: brightness(0) saturate(100%) invert(86%) sepia(69%) saturate(3454%) hue-rotate(318deg) brightness(75%) contrast(105%);
}

.content-container {
    margin: 0px var(--content-margin-left);
}

table.table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
}

table.table>thead>tr>th,
table.table>tbody>tr>td {
    border-color: var(--table-border-colour);
}

select:hover,
select:focus,
select,
select option {
    color: var(--dropdown-foreground-colour);
    background-color: var(--dropdown-background-colour);
}

a,
a:visited {
    color: var(--link-foreground-colour);
}

*:focus,
*:focus-visible {
    outline: 0px transparent !important;
    box-shadow: none !important;
}

body {
    margin: 0;
}

.rating-e {
    background-color: red;
}

.rating-d {
    background-color: darkorange !important;
}

.rating-c {
    background-color: rgb(255, 251, 0) !important;
}

.rating-b {
    background-color: rgb(173, 226, 50) !important;
}

.rating-a {
    background-color: green !important;
}

.text-bold,
.text-emphasise {
    font-weight: 500;
}

.text-small {
    font-size: 10px;
}

h3.section,
h1.section,
h2.section {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 18px;
    color: #222;
}

h2.section {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 22px;
    color: #222;
}

h1.section::before,
h1.section::after,
h2.section::before,
h2.section::after,
h3.section::before,
h3.section::after {
    content: "";
    background: #c0c0c0;
    height: 0.1em;
    margin: 0.2em;
    flex: 1;
}

h1.section.thin::before,
h1.section.thin::after,
h2.section.thin::before,
h2.section.thin::after,
h3.section.thin::before,
h3.section.thin::after {
    height: 1px;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: 'Roboto', Verdana, Tahoma, sans-serif;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

.hidden {
    display: none !important;
}

h1 {
    font-size: 36px;
}

h1,
h2,
h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

@media (min-width: 1200px) {
    .col-lg-6 {
        width: 50%;
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

table.sticky {
    border-collapse: separate;
}

app-toolbar.sticky,
div.sticky,
table.sticky>thead {
    position: sticky;
    top: 60px;
    z-index: 1;
}

table.sticky>thead>tr>th {
    background-color: var(--background-colour);
    border: 0;
    font-weight: 500;
}

table.sticky.under-tabs>thead {
    top: 97px;
}

table.sticky.under-toolbar>thead {
    top: 120px;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

table.table>thead>tr>th {
    color: var(--table-header-colour);
    text-align: left;
    padding: 6px;
    border: 0;
}

table.table>tbody>tr:first-child>td {
    border-top: 0;
}

table.table-striped>tbody>tr>td {
    border-top: none !important;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: var(--table-row-odd-background-colour);
}

.table-hover>tbody>tr:hover {
    background-color: var(--table-hover-background-colour);
    color: var(--table-hover-foreground-colour);
}

table.table-hover tr:hover td {
    cursor: pointer;
}

.table>thead>tr>th {
    border-bottom: 1px solid var(--table-border-colour) !important;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    border-top: 1px solid #eee;
    padding: 6px;
}

.form-group {
    margin-bottom: 15px;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: var(--background-colour);
    background-image: none;
    border: 1px solid var(--background-colour-muted);
}

/*
 * Fancy switch
 */


/* The switch - the box around the slider */

.switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 14px;
}

.inline-block {
    display: inline-block;
}

/* Hide default HTML checkbox */

.switch input {
    display: none;
}

/* The slider */

.checkboxslider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-colour-muted);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.checkboxslider:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input {
    border-radius: var(--input-border-radius);
}

input:checked+.checkboxslider {
    background-color: rgb(6, 128, 6) !important;
}

input:focus+.checkboxslider {
    box-shadow: 0 0 1px green !important;
}

input:checked+.checkboxslider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}


/* Rounded sliders */

.checkboxslider.round {
    border-radius: 24px;
}

.checkboxslider.round:before {
    border-radius: 50%;
}

.orange,
.amber {
    color: orange;
}

.red {
    color: red;
}

.green {
    color: green;
}

.blue {
    color: blue;
}

.gray {
    color: #888;
}

.dark-red {
    color: #b72929d9;
}

.help-source {
    cursor: help;
}

.mt-half {
    margin-top: .5rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}

.pt-1 {
    padding-top: 1rem !important;
}

.pb-1 {
    padding-top: 1rem !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.ml-1 {
    margin-left: 1rem !important;
}

.ml-half {
    margin-left: .5rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-half {
    margin-bottom: .5rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mr-1 {
    margin-right: 1em !important;
}

.mr-half {
    margin-right: .5em !important;
}

.fw600 {
    font-weight: 600;
}

section>header {
    font-size: 16px;
    font-weight: bold;
}

.dashboard.tiles .tile .subtitle {
    color: var(--foreground-colour);
    font-weight: 100;
}

.dashboard.tiles .tile.active .subtitle {
    font-weight: 100;
}

.dashboard.tiles.dark .tile.active .subtitle {
    color: #c7d0ff;
}

.dashboard.tiles .tile .large-text {
    color: var(--foreground-colour-muted);
    font-size: 22px;
    font-weight: 100;
}

.dashboard.tiles.dark .tile.active .large-text {
    color: var(--foreground-colour);
}


@media (max-width: 1050px) {
    .dashboard.tiles .tile .large-text {
        color: var(--foreground-colour);
        font-size: 18px;
        font-weight: 100;
    }

    .dashboard.tiles.dark .tile.active .large-text {
        color: white;
    }
}

.s3g-circle {
    fill: white;
    stroke: steelblue;
    stroke-width: 2px;
}

.s3g-overlay {
    fill: none;
    stroke: none;
    pointer-events: all;
}

.s3g-focusLine {
    fill: none;
    stroke: steelblue;
    stroke-width: 1px;
}

.s3g-axis-blue {
    stroke: rgb(2, 58, 104);
}

.s3g-rag-amber {
    opacity: 0.2;
    color: orangered;
    fill: orangered;
}

.s3g-rag-red {
    opacity: 0.1;
    color: rgb(255, 0, 0);
    fill: rgb(255, 0, 0);
}

.weather-axis text {
    fill: rgb(2, 58, 104);
}

.s3g-circle-annotation {
    fill: yellow;
    stroke: red;
    stroke-width: 2px;
}

/* theme v3 */

.theme4d>section {
    border: none;
    background-color: transparent;
    padding: 4px;
    margin: 10px 0;
}

.theme4d>section>header {
    display: flex;
    background-color: #ebe9e9;
    padding: 0 10px;
    border-radius: 4px;
}

.theme4d>section>header>div:nth-child(1) {
    flex: 1;
}

.theme4d>section>h1 {
    font-weight: 500;
    font-size: 18px;
    margin: 0;
    background-color: #ebe9e9;
    border-radius: 6px;
    padding: 4px 10px;
}

.theme4d>section .title {
    font-size: 20px;
    font-weight: 500;
}

.can-click {
    cursor: pointer;
}

/* PRIME FIXES */

@media (max-width: 414px) {
    .p-tabview .p-tabview-panels {
        padding: 0 !important;
    }

    /* mobile header */
    :root {
        --header-height: 43px;
    }
}

.p-inputtext {
    padding: 6px;
    background-color: transparent;
    color: var(--foreground-colour);
}

.p-button.p-button-secondary.input-color.p-button-outlined {
    border: 1px solid #ced4da;
    background-color: white;
    color: #495057;
}

.no-padding {
    padding: 0;
}

.p-tabview.no-padding .p-tabview-panels {
    padding: 0;
}

.p-tabview .p-tabview-nav .p-tabview-nav-link {
    font-weight: 400 !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    font-weight: 500 !important;
}


th.can-sort:hover {
    cursor: pointer;
}

th.can-sort>span {
    /*   padding-right 0 6px;*/
}

th.can-sort:hover>span {
    border-radius: 6px;
    cursor: pointer;
    color: var(--link-foreground-colour);
}

.flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.flex-centre {
    align-items: center;
}

/* OCCUPANCY */

.b-green,
.b-red,
.b-lightgreen,
.b-yellow,
.b-orange {
    font-weight: 500;
    color: black;
}

.f-green {
    color: green;
}

td.b-green,
.b-green {
    background-color: green !important;
}

.f-red {
    color: rgb(224, 3, 3);
}

td.b-red,
.b-red {
    background-color: rgb(224, 3, 3) !important;
}

.f-lightgreen {
    color: rgb(173, 226, 50);
}

td.b-lightgreen,
.b-lightgreen {
    background-color: rgb(173, 226, 50) !important;
}

.f-yellow {
    color: rgb(255, 251, 0);
}

td.b-yellow,
.b-yellow {
    background-color: rgb(255, 251, 0) !important;
}

.f-orange {
    color: darkorange;
}

td.b-orange,
.b-orange {
    background-color: darkorange !important;
}

/* primebg toolbar padding too large fix */

.p-toolbar.p-component {
    padding: 3px;
    background-color: #f1f1f1;
    border: none;
}

.p-button.p-button-xsm {
    font-size: 0.775rem;
    padding: 0.1375rem 0.275rem;
    line-height: 1em;
}

.p-button.p-button-xsm .p-button-icon-left {
    margin-right: 0.2rem;
}

/* bootstrap */

.container-fluid {
    margin-left: 0;
    margin-right: 0;
}

.__modal {
    position: fixed;
    top: 5vh;
    left: 10vw;
    width: 80vw;
    height: 90vh;
    z-index: 102;
    background-color: white;
    overflow: auto;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
}

.__modal.md {
    width: 400px;
    left: calc(50vw - 200px);
    height: 400px;
    top: calc(50vh - 200px);
}

.__modal.lg {
    width: 600px;
    left: calc(50vw - 300px);
    height: 600px;
    top: calc(50vh - 300px);
}

.__modal0 {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: .5;
    cursor: not-allowed;
    overflow: hidden;
    z-index: 101;
    position: fixed;
    animation: opac 1s;
    pointer-events: none;
}

.__modal>div {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    flex-wrap: nowrap;
    flex-direction: row;
    height: 100%;
}

.__modal>header {
    align-self: center;
    font-size: 20px;
    font-weight: 100;
    width: 100%;
    text-align: center;
}

.__modal>header>div>div.progress-line {
    margin-top: 0px;
}

.__modal>footer {
    align-self: center;
    padding: 10px;
}

.progress-line,
.progress-line:before {
    height: 3px;
    width: 100%;
    margin: 0;
}

.progress-line {
    background-color: #b3d4fc;
    display: -webkit-flex;
    display: flex;
}

.progress-line:before {
    background-color: #3f51b5;
    content: '';
    -webkit-animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@-webkit-keyframes running-progress {
    0% {
        margin-left: 0px;
        margin-right: 100%;
    }

    50% {
        margin-left: 25%;
        margin-right: 0%;
    }

    100% {
        margin-left: 100%;
        margin-right: 0;
    }
}

@keyframes running-progress {
    0% {
        margin-left: 0px;
        margin-right: 100%;
    }

    50% {
        margin-left: 25%;
        margin-right: 0%;
    }

    100% {
        margin-left: 100%;
        margin-right: 0;
    }
}

/* mdi spin faster */

.mdi-spin-x2:before {
    -webkit-animation: mdi-spin 1s infinite linear !important;
    animation: mdi-spin 1s infinite linear !important;
}

.opaque {
    opacity: .5;
}

.opaque8 {
    opacity: .8;
}

.opaque7 {
    opacity: .7;
}

.text-muted,
.dim {
    color: var(--foreground-colour-light);
}

.fourd {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    font-weight: normal;
    color: #495057;
}

.fourd.section-title {
    font-size: 22px;
    font-weight: 400;
    padding: 4px 0;
}

i.mdi {
    text-wrap: nowrap;
}

.fourd>section>h1 {
    font-weight: 400;
    font-size: 22px;
}

.fourd div[contenteditable="true"],
.fourd textarea,
textarea.fourd {
    color: var(--foreground-colour-muted);
    width: 100%;
    background-color: var(--background-colour);

    border: 1px solid var(--border-colour-muted);
    padding: 0.5rem;
}

.fourd select,
select.fourd {
    height: 39px;
    border-radius: 6px;
    border-color: #d0d0d0;
    width: 100%;
}

.fourd-group {
    padding: 1px;
}

input.fourd {
    color: var(--foreground-colour-muted);
    background-color: transparent;
    width: 100%;
    margin: 0;
    padding: 0.5rem;
    border: 1px solid var(--border-colour-muted);
    border-radius: var(--border-radius);
}

span.p-inputgroup-addon {
    color: var(--foreground-colour-muted);
    background-color: var(--background-colour-slightly-muted);
    border-color: var(--border-colour-muted);
}

textarea:focus,
input:focus {
    color: var(--foreground-colour-muted);
    border-color: var(--focus-foreground-colour) !important;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px var(--focus-foreground-colour);
}

table.fourd.center {
    margin: 0 auto;
}

.fourdtabs {
    display: flex;
    width: 100%;
    justify-items: center;
    border-bottom: 2px solid var(--tabs-border-colour);
    padding: 10px;
}

.fourdtabs>div {
    padding: 4px 10px;
    color: var(--tabs-tab-foreground-colour);
    white-space: nowrap;
}

.fourdtabs>div:hover {
    cursor: pointer;
}

.fourdtabs>div:last-child:hover {
    cursor: default;
}

.fourdtabs>div.active {
    border-bottom: 2px solid var(--tabs-tab-selected-colour);
    margin-bottom: -12px;
    color: var(--tabs-tab-selected-colour);
}

.fourdtabs>div:last-child {
    flex: 1;
    text-align: left;
}

.text-center,
table.table>thead>tr>th.text-center,
table.table>tbody>tr>td.text-center {
    text-align: center;
}

.text-right,
table.table>thead>tr>th.text-right,
table.table>tbody>tr>td.text-right {
    text-align: right;
}

.theme4d23 {
    margin: 10px;
}

.theme4d23 table {
    border: 1px solid #f0f0f0;
    width: 100%;
    margin-top: 10px;
    border-radius: 6px;
    border-collapse: unset;
    table-layout: fixed;
}

.theme4d23 table>thead>tr>th {
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    padding: 10px;
    color: #888;
    font-weight: 400;
    text-transform: uppercase;
}

.theme4d23 table>tbody>tr>td {
    border-top: 1px solid #f0f0f0;
    width: 100%;
    padding: 10px;
}

.theme4d23 table>tbody>tr:hover>td {
    background-color: #f9f9f9;
    cursor: pointer;
}

small.chip {
    padding: 2px 4px;
    border-radius: 6px;
    background-color: #f0f0f0;
    text-transform: lowercase;
    color: #888;
    font-weight: 100;
}

span.button {
    color: #607D8B;
    border: 1px solid #afafaf;
    padding: 1px 8px;
    display: inline-block;
    border-radius: 6px;
    opacity: .8;
    font-size: 16px;
}

.tabview-list {
    width: 100%;
    overflow: auto;
    margin-top: 10px;
    display: flex;
    margin-bottom: 2px;
    user-select: none;
}

.tabview-list>div {
    padding: 8px;
    display: inline-block;
    border-bottom: 2px solid var(--tabs-border-colour);
    -ms-user-select: none;
    user-select: none;
    color: var(--tabs-tab-foreground-colour);
    white-space: nowrap;
}

.tabview-list>div:last-child {
    flex: 1;
}

.tabview-list>div:hover {
    color: var(--link-foreground-colour);
    cursor: pointer;
    border-bottom: 2px solid var(--link-foreground-colour);
}

.tabview-list>div:last-child:hover {
    cursor: default;
    border-bottom: 2px solid var(--tabs-border-colour);
}

.tabview-list>div.active {
    color: var(--link-foreground-colour);
    border: none;
    border-bottom: 2px solid var(--link-foreground-colour);
}

.tabview-list>div.active>i.mdi,
.tabview-list>div:hover>i.mdi,
.tabview-list>div:hover {
    color: var(--link-foreground-colour);
    user-select: none;
}

.tabview-list>div.active:hover {
    cursor: default;
}

.tabview-content {
    padding-top: 6px;
}

.full-width-content {
    width: calc(100vw - 235px);
}

.lm-icons .full-width-content {
    width: calc(100vw - 90px);
}

/* #2196F3 */

.capitalise {
    text-transform: capitalize;
}

.italic {
    font-style: italic;
}

/* Tooltip container */
.tooltip {
    position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    background-color: var(--tooltip-background-colour);
    color: var(--tooltip-foreground-colour);
    text-align: center;
    padding: 5px 5px;
    border-radius: 4px;
    /* Position the tooltip text */

    z-index: 12;
    left: 0;
    top: -30px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip-right {
    top: -5px;
    left: 125%;
}

.tooltip-bottom {
    top: 135%;
    left: 50%;
    margin-left: -60px;
}

.tooltip-top {
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
}

.tooltip-left {
    top: -5px;
    bottom: auto;
    right: 128%;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--tooltip-background-colour) transparent transparent transparent;
}

.tooltip-top::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip-bottom::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
}

.tooltip-right::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
}

.can-hover {
    cursor: pointer;
}

/**
 * Insights requires a consistent width when printing.
 */
@media print {
    body {
        width: 950px;
    }
}

.reveal-on-hover>.reveal-on-hover-content {
    display: none;
}

.reveal-on-hover:hover>.reveal-on-hover-content {
    display: block;
}
