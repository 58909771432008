.fourd-design {}


.content-container h1,
.fourd-design h1 {
  font-size: 24px;
  font-weight: 300;
}

.content-container h2,
.fourd-design h2 {
  font-size: 22px;
  font-weight: 300;
}

.content-container h3,
.fourd-design h3 {
  font-size: 20px;
  font-weight: 300;
}

.content-container h4,
.fourd-design h4 {
  font-size: 18px;
  font-weight: 300;
}

.content-container h5,
.fourd-design h5 {
  font-size: 16px;
  font-weight: 300;
  color: var(--foreground-colour-dim);
}
