@font-face {
    font-family: 'MeteoconsRegular';
    src: url('/assets/font/meteocons-webfont.eot');
    src: url('/assets/font/meteocons-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/font/meteocons-webfont.woff') format('woff'),
         url('/assets/font/meteocons-webfont.ttf') format('truetype'),
         url('/assets/font/meteocons-webfont.svg#MeteoconsRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.weather-icons {
	font-family: 'MeteoconsRegular'
}
