@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ctjqw1');
  src:  url('fonts/icomoon.eot?ctjqw1#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ctjqw1') format('truetype'),
    url('fonts/icomoon.woff?ctjqw1') format('woff'),
    url('fonts/icomoon.svg?ctjqw1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-font_humidity:before {
  content: "\e904";
}
.icon-font_pm25:before {
  content: "\e903";
}
.icon-font_pm10:before {
  content: "\e902";
}
.icon-font_co2:before {
  content: "\e901";
}
.icon-font_Rules:before {
  content: "\e900";
}
