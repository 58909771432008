@import "lists.css";
@import "global-styles.css";
@import "fourd-design.css";
@import "../node_modules/c3/c3.min.css";

.flex {
  visibility: visible !important;
}

.flex>div>p>span {
  animation-delay: 1s;
  animation: flexin 4s;
}

.flex>div>p>svg {
  animation-delay: 0s;
  animation: flexin 2s;
}

@keyframes flexin {
  0% {
    opacity: .0;
  }

  100% {
    opacity: 1;
  }
}

.btn,
.form-control {
  border-radius: 0;
}

.row-eq-height {
  display: flex;
}


/* indexb.html */

.root-container {
  min-height: 100%;
  height: 100%;
  /* make the 3 columns equal height */
  display: table;
  width: 100%;
}


/* 2nd column 1st row to match 4D logo height */
.root-container>div:nth-child(2)>div:nth-child(1),
.root-container>div:nth-child(3)>div:nth-child(1) {
  height: var(--header-height);
  color: #6B7582;
  color: #d0d0d0;
}

/* Disable 4D Dashboard logo
.logo-placeholder:hover  a:nth-child(2) {
  display: block;
  opacity: 1;
  animation: fadeInFromNone 1s ease-out;
}
*/
@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0
  }

  1% {
    display: block;
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.logo-placeholder a:nth-child(2) {
  display: none;
  opacity: 0;
}

.root-container.authorised>div:nth-child(1)>div {
  visibility: visible;
  /*transition: all 0.4s linear;*/
  opacity: 1;
}


a.website-link {
  width: 100%;
  height: 100%;
  display: block;
}

/* ===================================================
 *                        COLUMN 2
 * ===================================================
 *                        filters
 */

.root-container>div:nth-child(2) {
  visibility: hidden;
  background-color: #282C37;
  width: 10px;
  height: 100%;
  float: left;
  border-left: 1px solid #000;
  opacity: 0;
  overflow: hidden;
}

.root-container.authorised>div:nth-child(2) {
  visibility: visible;
  /*transition: all 0.4s linear;*/
  width: 260px;
  opacity: 1;
}

/*
 * COLUMN 2, ROW 1 ------------------
 */

.root-container>div:nth-child(2)>div:nth-child(1) {
  padding-left: 10px;
  padding-top: 30px;
}

.root-container>div:nth-child(2)>div:nth-child(1)>span::after {
  width: 24px;
  content: url('assets/list-clear.png');
  margin-left: 40px;
}

.root-container>div:nth-child(2)>div:nth-child(1)>span:hover::after {
  cursor: pointer;
  background-color: rgb(46, 46, 46);
  border: 1px dotted;
}

/* engagement start */
.root-container>div:nth-child(2).display-engagement {
  background-color: var(--background-colour);

  border-right: 1px solid #f0f0f0;
  overflow: inherit !important;
  overflow-y: inherit !important;
}

.root-container>div:nth-child(2).display-engagement>div:nth-child(1) {
  padding-left: 10px;
  padding-top: 10px;
}

.root-container>div:nth-child(2).display-engagement+div>div.container-fluid {
  margin: 0;
  padding: 0;
}

/* engagement end */

/* ===================================================
 *                        COLUMN 3
 * ===================================================
 */

.root-container>div:nth-child(3) {
  /*margin-left: 90px;*/
  margin-left: 180px;

  /* full height column */
  min-height: calc(100vh - 82px);
  background-color: var(--background-colour);
}

.root-container.menu-v2>div:nth-child(3) {
  margin-left: 180px;
}

/*
 * COLUMN 3, ROW 1 ------------------
 */
.root-container>div:nth-child(3)>div:nth-child(1) {
  /*padding: 30px;*/
  font-size: 25px;
  color: #989898;
  /*padding-bottom: 50px;*/
  background-color: var(--background-colour);
  position: fixed;
  top: 0;
  z-index: 10;
}

/* Remove elements when not authorised */
.root-container.authorised>div:nth-child(3)>div:nth-child(1) {
  border-bottom: 1px solid #c9c9c9;
}

#container {
  margin-top: var(--page-margin-top);
  /*height: 100%;*/
  min-height: calc(100vh - var(--page-margin-top));
}


.root-container>div:nth-child(3)>div:nth-child(1) h1 {
  margin-bottom: 26px;
}

.root-container>div:nth-child(3)>div:nth-child(1)>i {
  cursor: pointer;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.aligned-row {
  display: flex;
  flex-flow: row wrap;
}

.aligned-row::before {
  display: block;
}

.detail-container {
  border: 1px solid #f0f0f0;
  padding: 10px;
  background-color: #f0f0f0;
}

.detail-container>.row:nth-child(1) {
  background-color: #d6d6d6;
}

.detail-containers>div:last-child {
  border-bottom: none;
}


/* primegaces overrides */

p-multiselect {
  font-size: 1rem;
}

body,
html {
  font-family: 'Roboto', Verdana, Tahoma, sans-serif;
  height: 100%;
  font-size: 16px;
  font-weight: 300;
}

body .ui-multiselect {
  border-radius: 0;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, .15);
}

body .ui-multiselect .ui-multiselect-trigger .fa {
  margin-top: .4em;
}

.ui-multiselect .ui-multiselect-label {
  padding-bottom: 0;
}

body .ui-multiselect .ui-multiselect-item.ui-state-highlight {
  background-color: #02ACEE;
  color: #ffffff;
}

.no-screen {
  display: none;
}

.p-dialog {
  animation: none !important;
  transform: none !important;
}

/*
 * Printing
 */
@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }

  .no-screen {
    display: block !important;
  }

  .container {
    padding-left: 0;
  }

  #container {
    margin-top: 0;
  }

  .root-container>div:nth-child(3) {
    margin-left: 0 !important;
  }

  .pagebreakbefore {
    page-break-before: always;
  }

  .pagebreakafter {
    page-break-after: always;
  }

  /*
  .report-view-title {
    position: static;
    top: auto;
    box-shadow: none;
    border: none;
  }
*/
  .p-tabview-nav {
    display: none !important;
  }
}

@media screen {

  .print-preview .no-print,
  .print-preview * {
    display: block !important;
  }

  .print-preview .pagebreakbefore {
    border-top: 2px dashed #8500ff;
  }
}

/* General hover animations */

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
  cursor: pointer;
}

.hvr-backward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-backward:hover,
.hvr-backward:focus,
.hvr-backward:active {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
}

.hvr-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-forward:hover,
.hvr-forward:focus,
.hvr-forward:active {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}

/* Simulate bootstrap 4 card */

.card {
  border: 1px solid #888;
}

.card-block {
  padding: 6px;
}

.card-title {
  font-weight: bold;
  text-decoration: underline;
}

button.btn-secondary {
  color: var(--foreground-colour);
  background-color: var(--background-colour);
  border-color: #a0a0a0;
}

button.btn-secondary:hover {
  color: var(--background-colour);
  background-color: var(--background-colour-muted);

}

.card button {
  margin-top: 4px;
}

/* SPINNERS */
.loader,
.loader:before,
.loader:after {
  background: #66b3ff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #66b3ff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

/* Bottom right icon */
.bottom-right {
  z-index: 5 !important;
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  width: 60px !important;
  height: 60px !important;
  /*border-radius: 50%!important;*/
  /*background-color: #757575;*/
  transition: box-shadow 80ms ease-in-out !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .06), 0 2px 32px rgba(0, 0, 0, .16) !important;
}

.root-container .slider {
  box-shadow: none;
  /*border: 1px dotted #888;*/
  transition: border .5s linear, box-shadow .5s linear;
  background-color: var(--background-colour-slightly-muted) !important;
}

.root-container.scrolled .slider {
  /*box-shadow: 0 1px 6px rgba(0, 0, 0, .06), 0 2px 12px rgba(0, 0, 0, .66);*/
  /* border: 1px solid #ccc;*/
  transition: border .5s linear, box-shadow .5s linear, background-color .5s;
  background-color: var(--background-colour-slightly-muted) !important;
}

/*
.root-container .report-view-title {
  box-shadow: none;
  border: none;
  transition: border .5s linear, box-shadow .5s linear;
  background-color: white !important;
}

.root-container.scrolled .report-view-title {
  box-shadow: 0 1px 6px rgba(0, 0, 0, .06), 0 2px 12px rgba(0, 0, 0, .66);
  border: 1px solid #888;
  transition: border .5s linear, box-shadow .5s linear, background-color .5s, padding .5s;
  background-color: #6b7582 !important;
  color: white;
  padding: 8px;
}
*/
.scroll-to-top div {
  opacity: 0;
  transition: opacity 1s linear;
}

.scroll-to-top>div:nth-child(1) {
  font-size: 20px !important;
  padding-left: 36px !important;
}

.scroll-to-top.enabled div {
  opacity: .8;
  transition: opacity 1s linear;
  z-index: 1;
}

.page-info {
  background-color: #f0f0f0;
  padding: 6px;
  position: relative;
  overflow: hidden;
  padding-left: 80px;
}

.page-info p.title {
  font-size: 20px;
}

.page-info p.body {
  font-style: italic;
}

.page-info .image {
  position: absolute;
  opacity: 0.1;
  font-size: 60px;
  left: -1px;
  top: 0px;
}

body .ui-button,
.ui-calendar.ui-calendar-w-btn input {
  border-radius: 0;
}

.isDesktop {
  display: inline-block;
}

table>tbody>tr.isDesktop {
  display: table-row;
}

table>thead>tr>th.isDesktop,
table>tbody>tr>td.isDesktop {
  display: table-cell;
}

.isDesktop.block {
  display: block;
}

.isDesktop.inherit {
  display: inherit;
}

.isMobile {
  display: none;
}

.isMobileLandscape {
  display: none;
}

@media (max-width: 414px) {

  #container {
    margin-top: var(--page-margin-top-mobile);
  }

  .isDesktop,
  table>tbody>tr>td.isDesktop,
  table>thead>tr>th.isDesktop {
    display: none;
  }

  .isMobile {
    display: inline-block;
  }

  table>thead>tr.isMobile,
  table>tbody>tr.isMobile {
    display: table-row;
  }
}

@media (max-width: 850px) and (max-height: 400px) {

  .isMobileLandscape {
    display: inline-block;
  }


}

.status-online {
  color: green;
}

.status-offline {
  color: red;
}

.status-standby {
  color: orange;
}

/* Turn off primes rounded corners */
.ui-corner-all {
  border-radius: 0;
}

/* IE scrollbar fix -- stop it floating! */
body {
  -ms-overflow-style: scrollbar !important;
}

.link {

  text-decoration: underline;
  text-decoration-color: #a0a0a0 !important;
  -webkit-text-decoration-color: #a0a0a0 !important;
}

.link:hover {
  cursor: pointer;
}

._btn {
  border-radius: 10px;
}

._btn:active,
._btn:focus {
  outline: none !important;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
  outline-offset: 0;
}

:focus {
  outline: none !important;
}

@media (max-width:500px) {
  .left-menu {
    display: none;
  }
}

@media (min-width:500px) {
  .left-menu {
    width: 180px !important;
    /* background-color: blue !important;*/
    z-index: 11 !important;
    height: calc(100vh - 50px);
    background-color: var(--background-colour) !important;
  }

  .authorised .left-menu {
    border-right: 1px solid var(--border-colour-muted);
  }
}

@media print {

  i.green,
  i.note.colour.green,
  i.note.colour.green:before,
  i.note.colour.green::before,
  .green::before,
  .green::after {
    color: green !important;
  }

  .amber,
  .amber:before,
  .amber:after {
    color: orange !important;
  }

  .red,
  .red:before,
  .red:after {
    color: red !important;
  }
}

@media (max-height: 730px) {}


.is-logging-in .left-menu {
  display: none !important;

}

.root-container.is-logging-in.menu-v2>div:nth-child(2) {
  position: inherit;
  height: calc(100vh - 1px);
  overflow-y: scroll;
}

.root-container.is-logging-in.menu-v2>div:nth-child(3) {
  margin-left: 0;
  min-height: auto;
  height: 100%;
}

.root-container.is-logging-in.menu-v2>div:nth-child(3)>div:nth-child(1) {
  padding: 0 !important;
  margin: 0 !important;
  position: inherit;
  z-index: 0;
  box-shadow: none !important;
  top: auto;
}

.root-container.is-logging-in.menu-v2>div>#container {
  margin-top: 0;
}
