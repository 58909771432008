.component-list h1{
    font-size: 20px;
    color: #0000f0;
}

.component-list > table > thead > tr > th{
    border: none;
}


.component-list > table th{
    color: #999;
    font-weight: 400;
}

.component-list.detailed {

}


.component-list > table > tbody > tr:hover {
    cursor: pointer;
}